<template>
  <div class="ground-content">
    <div
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class=""
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/groundPage/landingPageTT"
          @click.native="handleTabClick('/groundPage/landingPageTT')"
        >头条落地页
        </el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/groundPage/landingPageBD"
          @click.native="handleTabClick('/groundPage/landingPageBD')"
        >百度落地页
        </el-menu-item
        >
      </el-menu>
    </div>
    <div
      class="ground-table border-radius-6 box-shadow-light-grey padding-20"
      style="padding-top: 16px"
    >
      <!-- 搜索bar -->
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button
            class="margin-right-twentyFour"
            @click="handleEdit"
            type="primary"
            icon="el-icon-plus"
          >添加网站
          </el-button
          >
          <el-select
            class="margin-right-twentyFour"
            style="width: 150px"
            v-model="accountChannelId"
            filterable
            placeholder="公众号"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="item in availableOfficialList"
              :key="item.id"
              :value="item.id"
              :label="item.channelName"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
          <el-input
            v-model="searchWords"
            style="width: 300px"
            placeholder="网站域名/标题/备注"
            clearable
            @clear="searchWords = null"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearchWords"
            ></el-button
            >
          </el-input>
        </div>
        <div
          v-if="landingType === 2"
          class="baidu-input"
          style="margin-left: auto"
        >
          <el-input
            v-model="baiduUrl"
            style="width: 300px"
            placeholder="联调url"
            clearable
            @clear="baiduUrl = null"
          >
            <el-button
              v-loading="baiduLoading"
              slot="append"
              @click="handleBaiduUrl"
            >联调
            </el-button
            >
          </el-input
          >
        </div>
      </div>
      <!-- table主体 -->
      <input class="hidden-input" type="text"/>
      <el-table
        class="main-table"
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 328px)"
        style="width: 100%"
      >
        <el-table-column prop="id" min-width="50" label="ID"></el-table-column>
        <el-table-column prop="link" label="网站链接" min-width="130">
        </el-table-column>
        <el-table-column
          prop="title"
          min-width="120"
          label="标题"
        ></el-table-column>
        <el-table-column
          prop="channelName"
          min-width="100"
          label="公众号"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          min-width="100"
          label="公司名"
        ></el-table-column>
        <el-table-column
          prop="wechatNumber"
          min-width="100"
          label="微信号"
        ></el-table-column>
        <el-table-column prop="type" min-width="76" label="平台">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1">自建站</span>
            <span v-else-if="scope.row.type === 2 && landingType === 1"
            >橙子建站</span
            >
            <span v-else-if="scope.row.type === 2 && landingType === 2"
            >基木鱼</span
            >
            <!-- {{ scope.row.type | typeFilter }} -->
          </template>
        </el-table-column>
        <el-table-column prop="landingType" label="推广目的" min-width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.landingType === 'LINK'">线索收集</span>
            <span v-if="scope.row.landingType === 'APP'">应用推广</span>
            <span v-if="scope.row.landingType === 'QUICK_APP'">快应用</span>
          </template>
        </el-table-column>
        <el-table-column prop="linkType" label="跳转类型" min-width="80" v-if="this.landingType === 1">
          <template slot-scope="scope">
            <span v-if="scope.row.linkType === 1">快应用</span>
            <span v-if="scope.row.linkType === 2">小程序</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="linkAddress"
          show-overflow-tooltip
          min-width="100"
          label="关注链接"
        >
        </el-table-column>
        <!-- <el-table-column prop="content" label="内容" min-width="100">
        </el-table-column> -->
        <el-table-column prop="wxHeadUrl" label="微信头像" min-width="80">
          <template slot-scope="scope">
            <img v-if="scope.row.wxHeadUrl" :src="scope.row.wxHeadUrl" alt=""/>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop="headUrl" label="顶图" min-width="80">
          <template slot-scope="scope">
            <img v-if="scope.row.headUrl" :src="scope.row.headUrl" alt=""/>
            <span v-else>暂无</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="80">
        </el-table-column>
        <el-table-column prop="callbackRate" label="回传率" min-width="70">
          <template slot-scope="scope">
            {{ scope.row.callbackRate + "%" }}
          </template>
        </el-table-column>
        <el-table-column prop="startDate" label="回传时间" min-width="130">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.settingTimeList &&
                scope.row.settingTimeList.length <= 2
              "
            >
              <p
                style="font-size: 12px"
                v-for="(item, index) in scope.row.settingTimeList"
                :key="index"
              >
                {{ item.startDate + " - " + item.endDate }}
              </p>
            </div>
            <div
              v-if="
                scope.row.settingTimeList &&
                scope.row.settingTimeList.length > 2
              "
            >
              <el-popover placement="top" width="160" trigger="hover">
                <p
                  v-for="(item, index) in scope.row.settingTimeList"
                  :key="index"
                >
                  {{ item.startDate + " - " + item.endDate }}
                </p>
                <span slot="reference">
                  <p
                    style="font-size: 12px"
                    v-for="(item, index) in scope.row.settingTimeList.slice(
                      0,
                      2
                    )"
                    :key="index"
                  >
                    {{ item.startDate + " - " + item.endDate }}
                  </p>
                  <p style="height: 10px; line-height: 10px">...</p>
                </span>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="action" width="300" label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="handleCopyLink(scope.row)"
              class="button-small"
            >检测链接
            </el-button
            >
            <el-button
              type="primary"
              @click="handleEdit(scope.row)"
              class="button-small"
            >编辑
            </el-button
            >
            <el-dropdown
              @command="(val) => handleMore(val, scope.row)"
              trigger="click"
              style="margin-left: 10px"
            >
              <el-button type="primary" class="button-small">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="callback">回传数据</el-dropdown-item>
                <el-dropdown-item command="unCallback"
                >未回传数据
                </el-dropdown-item
                >
                <el-dropdown-item command="uploadLink"
                >访问数据
                </el-dropdown-item
                >
                <el-dropdown-item command="delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        style="margin: 20px 0; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 新增编辑页面 -->
      <!-- 编辑新增dialog -->
      <el-dialog
        :title="isEdit ? '编辑网站' : '新增网站'"
        :visible.sync="showEdit"
        :fullscreen="true"
        :destroy-on-close="true"
        center
        class="editResource"
        @close="handleCancle"
        v-loading="loadingEditor"
      >
        <el-row :gutter="20">
          <el-form
            label-position="left"
            label-width="130px"
            class="form-container"
          >
            <!-- 表单顶部 -->
            <!-- 表单中部 -->
            <div class="form-body">
              <div class="form-body-left">
                <el-form-item class="necessary" label="公众号：">
                  <el-select
                    style="width: 100%"
                    v-model="sendForm.accountChannelId"
                    value-key="id"
                    filterable
                    placeholder="请选择公众号"
                    @change="handleEditChannelChange"
                  >
                    <el-option
                      v-for="item in officialList"
                      :key="item.id"
                      :value="item"
                      :label="item.channelName"
                    >
                      <span style="float: left">{{ item.channelName }}</span>
                      <span style="float: right; font-size: 13px"
                      >{{ item.platformStr }} - {{ item.uname }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="necessary" label="公众号名称：">
                  <el-input
                    v-model="sendForm.channelName"
                    placeholder="请公众号名称"
                  ></el-input>
                </el-form-item>
                <el-form-item class="necessary" label="微信号：">
                  <el-input
                    v-model="sendForm.wechatNumber"
                    placeholder="请填写微信号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="微信号头像：" style="height: 120px">
                  <div
                    @click="handleClickWxImg"
                    v-if="!wximgUrl"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 30px;
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      border: 1px solid #dcdfe6;
                      border-radius: 4px;
                      cursor: pointer;
                    "
                  >
                    <div><i class="el-icon-plus"></i></div>
                  </div>
                  <img
                    v-if="wximgUrl"
                    class="headImg"
                    :data-set="sendForm.wxHeadUrl"
                    :src="wximgUrl"
                    @click="handleClickWxImg"
                    style="
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      cursor: pointer;
                    "
                  />
                  <div
                    class="img-over"
                    v-if="wximgUrl"
                    @click="handleDeleteWxImg"
                  >
                    <i class="el-icon-delete icon"></i>
                  </div>
                  <input
                    type="file"
                    ref="file"
                    @change="WxImgFileChange"
                    name="file"
                    accept="image/*"
                    class=""
                    style="position: absolute; opacity: 0; top: 0; left: 0"
                  />
                  <!-- <upload-image
                    ref="upload"
                    :url="sendForm.wxHeadUrl"
                    @uploadSuccess="handleUploadWxImg"
                    width="320"
                    height="160"

                  />
                  <img
                    v-if="wximgUrl"
                    class="headImg"
                    :data-set="sendForm.wxHeadUrl"
                    :src="wximgUrl"
                    alt=""
                  />
                  <div
                    class="img-over"
                    v-if="wximgUrl"
                    @click="handleDeleteWxImg"
                  >
                    <i class="el-icon-delete icon"></i>
                  </div> -->
                </el-form-item>
                <el-form-item class="necessary" label="网站链接：">
                  <el-input
                    v-model="sendForm.link"
                    placeholder="请填写网站链接"
                  ></el-input>
                </el-form-item>
                <el-form-item label="标题：">
                  <el-input
                    v-model="sendForm.title"
                    placeholder="请填写标题"
                  ></el-input>
                </el-form-item>
                <el-form-item label="备注：">
                  <el-input
                    v-model="sendForm.remark"
                    placeholder="请填写备注消息"
                  ></el-input>
                </el-form-item>
                <el-form-item class="necessary" label="公司名：">
                  <el-input
                    v-model="sendForm.companyName"
                    placeholder="请填写公司名"
                  ></el-input>
                </el-form-item>
                <el-form-item class="necessary" label="平台：">
                  <el-select
                    class="margin-right-ten"
                    style="width: 100%"
                    v-model="sendForm.type"
                    value-key="id"
                    filterable
                    placeholder="请选择平台"
                  >
                    <el-option
                      v-for="item in tempWechat"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="necessary" label="推广目标：">
                  <el-select
                    class="margin-right-ten"
                    style="width: 100%"
                    v-model="sendForm.landingType"
                    placeholder="请选择推广目标"
                  >
                    <el-option label="线索收集" :value="'LINK'"></el-option>
                    <el-option label="快应用" :value="'QUICK_APP'"></el-option>
                    <el-option label="应用推广" :value="'APP'"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="necessary" label="链接类型：" v-if="this.landingType === 1 && this.showWangEditor">
                  <el-select
                    class="margin-right-ten"
                    style="width: 100%"
                    v-model="sendForm.linkType"
                    placeholder="请选择链接类型"
                  >
                    <el-option label="快应用" :value="1"></el-option>
                    <el-option label="小程序" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <template v-if="this.showWangEditor">
                  <el-form-item label="背影色：">
                    <div class="block">
                      <el-color-picker v-model="sendForm.backgroundColor"></el-color-picker>
                    </div>
                  </el-form-item>
                </template>
                <template v-if="this.showColorEditor">
                  <el-form-item class="necessary" label="跳转链接：">
                    <el-input
                      v-model="sendForm.linkAddress"
                      placeholder="请填写跳转链"
                    ></el-input>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item class="necessary" label="关注链接：">
                    <el-input
                      v-model="sendForm.linkAddress"
                      placeholder="请填写关注链接"
                    ></el-input>
                  </el-form-item>
                </template>
                <el-form-item class="necessary" label="回传率：">
                  <el-input-number
                    style="width: 165px"
                    v-model="sendForm.callbackRate"
                    placeholder="请输入回传率"
                    :precision="2"
                    :step="1"
                    :min="0"
                  ></el-input-number>
                </el-form-item>
                <el-form-item class="necessary" label="回传时间">
                  <div class="time-list">
                    <div
                      v-for="(item, index) in timeList"
                      :key="index"
                      class="time-item"
                    >
                      <el-time-picker
                        :picker-options="{
                          selectableRange: '00:00:01 - 23:59:59',
                        }"
                        value-format="HH:mm:ss"
                        style="width: 140px"
                        placeholder="起始时间"
                        v-model="item.startDate"
                      >
                      </el-time-picker>
                      <span style="margin-left: 5px">-</span>
                      <el-time-picker
                        :picker-options="{
                          selectableRange: '00:00:01 - 23:59:59',
                        }"
                        value-format="HH:mm:ss"
                        placeholder="结束时间"
                        style="width: 140px; margin-left: 5px"
                        v-model="item.endDate"
                      >
                      </el-time-picker>

                      <el-button
                        v-if="index === timeList.length - 1"
                        type="primary"
                        class="add-time"
                        style="padding: 5px; margin-left: 4px"
                        icon="el-icon-plus"
                        @click="handleAddTime"
                        circle
                      ></el-button>
                      <el-button
                        v-if="index !== 0"
                        type="danger"
                        class="add-time"
                        style="padding: 5px; margin-left: 4px"
                        icon="el-icon-close"
                        @click="handleDeleteTime(index)"
                        circle
                      ></el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="书籍：" style="margin-bottom: 14px">
                  <div class="select-book">
                    <p>
                      <span v-if="bookDetail"
                      >{{ bookDetail.book.bookName }}&nbsp;/&nbsp;截止第{{
                          bookDetail.chapter.endIdx
                        }}章</span
                      >
                      <span v-else>请选择书籍</span>
                    </p>
                    <template v-if="bookDetail">
                      <!-- 针对阳光书城 、忧书阁 、七悦-->
                      <el-popover
                        placement="top"
                        v-if="
                          selectChannel.platform === 3 ||
                          selectChannel.platform === 4 ||
                          selectChannel.platform === 6
                        "
                        trigger="hover"
                      >
                        <div class="button-list">
                          <el-button type="primary" @click="getResourceType(1)"
                          >文案
                          </el-button
                          >
                          <el-button type="warning" @click="getResourceType(2)"
                          >图片
                          </el-button
                          >
                          <el-button type="danger" @click="getResourceType(3)"
                          >背景图
                          </el-button
                          >
                        </div>
                        <el-button slot="reference">图文详情</el-button>
                      </el-popover>
                      <el-button v-else @click="handleIframe"
                      >图文详情
                      </el-button
                      >
                      <i
                        class="el-icon-question"
                        v-if="bookDetail"
                        title='---首次点击“图文详情”时可能会新打开对应平台的登录页面的窗口，登录之后最好关闭窗口在进行相应的操作。
---如果点击“图文详情”之后弹出的页面长时间未加载或者显示的是登录页面(海读书城)，可以尝试"刷新页面"！'
                      ></i>
                    </template>
                    <el-button
                      type="primary"
                      @click="handleBookShow"
                      style="margin-right: 10px"
                    >选择书籍
                    </el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item
                  label="顶图："
                  :class="{ necessary: showWangEditor }"
                  style="height: 160px"
                >
                  <div
                    @click="handleClickDingImg"
                    v-if="!imgUrl"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 30px;
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      border: 1px solid #dcdfe6;
                      border-radius: 4px;
                      cursor: pointer;
                    "
                  >
                    <div><i class="el-icon-plus"></i></div>
                  </div>
                  <img
                    v-if="imgUrl"
                    class="headImg"
                    :data-set="sendForm.HeadUrl"
                    :src="imgUrl"
                    @click="handleClickDingImg"
                    style="
                      width: 100px;
                      height: 100px;
                      margin-right: 10px;
                      cursor: pointer;
                    "
                  />
                  <div class="img-over" v-if="imgUrl" @click="handleDeleteImg">
                    <i class="el-icon-delete icon"></i>
                  </div>
                  <input
                    type="file"
                    ref="Dingfile"
                    @change="DingImgFileChange"
                    name="file"
                    accept="image/*"
                    class=""
                    style="position: absolute; opacity: 0; top: 0; left: 0"
                  />
                </el-form-item>
              </div>
              <div class="form-body-right" v-show="showWangEditor">
                <div id="wangEditor" style="height: 830px; width: 400px"></div>
                <back-up-down :target="editorBox"></back-up-down>
              </div>
            </div>
          </el-form>
        </el-row>
        <span slot="footer">
          <el-button @click="handleCancle">取消</el-button>
          <el-button
            type="primary"
            @click="handleSubmit"
            :loading="editorButton"
          >确定</el-button
          >
        </span>
        <!-- 书籍选择dialog -->
        <chooseBook
          :showBook="showBook"
          @close="showBook = false"
          @success="bookChooseSuccess"
          :channel="selectChannel"
        />
      </el-dialog>
      <template v-if="bookDetail">
        <el-dialog
          :title="`${
            this.selectChannel.platform !== undefined &&
            this.selectChannel.platform !== null
              ? messagePlatformListDetail.find(
                  (item) => item.id === this.selectChannel.platform
                ).name
              : ''
          }  ${bookDetail.book.bookName}--截止到第${
            bookDetail.chapter.endIdx
          }章`"
          width="60vw"
          top="2vh"
          class="iframe-dialog"
          v-dialogDrag
          :visible.sync="showIframe"
          :before-close="handleIframeClose"
        >
          <div class="refresh" style="margin-bottom: 10px">
            <el-button @click="refreshIframePage">刷新页面</el-button>
          </div>
          <div class="loading-div" v-if="iframeLoading">
            内容加载中，请稍后...
          </div>
          <iframe
            :src="bookSrc"
            name="ifrmname"
            ref="iframe"
            class="iframe-resource"
            frameborder="0"
          ></iframe>
        </el-dialog>
      </template>
      <!-- 详情 -->
      <el-drawer
        :title="'回传数据：' + DetailData.channelName"
        :visible.sync="showDetail"
        size="75%"
        :destroy-on-close="true"
      >
        <landingDetail :detail="DetailData" :landingType="landingType"/>
      </el-drawer>
      <!-- 访问数据 -->
      <el-drawer
        :title="'访问数据：' + DetailData.channelName"
        :visible.sync="showUploadLink"
        size="75%"
        :destroy-on-close="true"
      >
        <landingUpload :detail="DetailData" :landingType="landingType"/>
      </el-drawer>
      <!-- 未回传数据 -->
      <el-drawer
        :title="'未回传数据：' + DetailData.channelName"
        :visible.sync="showUnCallback"
        size="90%"
        :destroy-on-close="true"
      >
        <unCallback :detail="DetailData" :landingType="landingType"/>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {uploadFile} from "@/api/common";
import landingDetail from "./landingDetail.vue";
import landingUpload from "./landingUpload.vue";
import unCallback from "./unCallback.vue";
import {mapGetters} from "vuex";
import {compressUpload} from "@/api/novelPut";
import {messagePlatformListDetail} from "@/assets/js/options";
import {
  getGroundList,
  addGroundList,
  deleteGroundList,
  updateGroundList,
  BaiduLandingPageLink,
} from "@/api/groundPage";
import {messagePlatformList} from "@/assets/js/options";
import {getLoginAccount} from "@/api/account";
import UploadImage from "@/components/UploadImage";
import backUpDown from "@/components/upDown";
import Editor from "wangeditor";
import chooseBook from "../../message/mediaResource/chooseBook.vue";
import {getAccountStatus} from "@/api/service";

export default {
  name: "Consume",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
      // tab切换
      loading: false,
      page: 1,
      pageSize: 15,
      total: 0,
      isEdit: false,
      showEdit: false,
      searchWords: null,
      tableData: [],
      sendForm: {},
      wangEditor: null,
      bookDetail: null,
      loadingEditor: false,
      messagePlatformListDetail,
      showIframe: false,
      showBook: false,
      editorButton: false,
      selectChannel: null,
      tempWechat: [
        {id: 1, name: "自建站"},
        {id: 2, name: "橙子建站"},
      ],
      editorBox: null,
      accountChannelId: null,
      testChannelInfo: null,
      // extendId: "figgdochegaejmdigdncnidhdpdeaaog", // 线上插件ID
      // extendId: "gdpdodfafaiacgmnmchoikalikiemakp", // 线下插件Id
      extendId: process.env.VUE_APP_BASE_extendId, //'devT'
      resourceType: null,
      iframeLoading: false,
      host: null, // 平台host相关信息
      isIframeLoadComplete: false, // iframe是否加载完成
      bookSrc: "",
      imgUrl: "",
      wximgUrl: "",
      editId: null,
      showWangEditor: true,
      showColorEditor: true,
      isFirstInEdit: false,
      timeList: [
        {
          startDate: "09:00:00",
          endDate: "18:00:00",
        },
      ],
      showDetail: false,
      showUploadLink: false,
      DetailData: {},
      landingType: 1,
      baiduUrl: "",
      baiduLoading: false,
      showUnCallback: false,
    };
  },
  watch: {
    showEdit: function (newV) {
      if (newV) {
        this.$nextTick(() => {
          this.loadingEditor = false;
          if (!this.wangEditor) {
            this.wangEditor = new Editor("#wangEditor");
            this.wangEditor.config.zIndex = 1999;
            this.wangEditor.config.pasteFilterStyle = false;
            this.wangEditor.config.pasteTextHandle = function (pasteStr) {
              // var reg = /(<img[^>]*)(\/{1}>)/gi;
              pasteStr = pasteStr
                .replace(/(<img[^>]*)(\/{1}>)/gi, "$1 width='100%' $2")
                .replace(/(<div\s{0,}(.*?)>)/gim, "<p>")
                .replace(/<\s{0,}\/div>/gim, "</p>");
              return pasteStr;
            };
            this.wangEditor.config.height = 700;
            this.wangEditor.config.uploadImgMaxLength = 1;
            this.wangEditor.config.menus = [
              "head",
              "bold",
              "fontSize",
              "fontName",
              "italic",
              "underline",
              "strikeThrough",
              "indent",
              "lineHeight",
              "foreColor",
              "backColor",
              "link",
              "quote",
              "image",
              "table",
              "code",
              "splitLine",
              "undo",
              "redo",
              "list",
              "todo",
              "justify",
            ];
            this.wangEditor.config.fontSizes = {
              "x-small": {name: "16px", value: "1"},
              small: {name: "17px", value: "2"},
              normal: {name: "18px", value: "3"},
              large: {name: "19px", value: "4"},
              "x-large": {name: "20px", value: "5"},
              "xx-large": {name: "21px", value: "6"},
              "xxx-large": {name: "22px", value: "7"},
              "xxxx-large": {name: "23px", value: "8"},
              "xxxxx-large": {name: "24px", value: "9"},
            };
            this.wangEditor.create();
            // 上传图片
            this.wangEditor.config.customUploadImg = (
              resultFiles,
              insertImgFn
            ) => {
              compressUpload({file: resultFiles[0]})
                .then((res) => {
                  insertImgFn(res);
                })
                .catch((err) => {
                });
            };
            if (this.isEdit) {
              this.wangEditor.txt.html(this.sendForm.content);
            }
            // 获取wangeditor dom
            if (document.getElementsByClassName("w-e-text")) {
              this.editorBox = document.getElementsByClassName("w-e-text")[0];
            }
          }
        });
      }
    },
    bookSrc: {
      handler() {
        this.isIframeLoadComplete = false;
      },
    },
    "sendForm.type": function (newV) {
      if (newV === 2) {
        //橙子建站平台不需要内容
        this.showWangEditor = false;
        this.$set(this.sendForm, "linkType", 1);
        this.$set(this.sendForm, "backgroundColor", "");
      } else {
        this.showWangEditor = true;
      }
    },
    "sendForm.linkType": function (newV) {
      if (newV === 1) {
        this.showColorEditor = false;
      } else {
        this.showColorEditor = true;
      }
    },
    $route: {
      handler(newVal) {
        this.activeSubMenu = newVal.path;
        this.tabName = newVal.path;
        if (this.$route.name == "LandingPageTT") {
          this.landingType = 1;
          this.tempWechat = [
            {id: 1, name: "自建站"},
            {id: 2, name: "橙子建站"},
          ];
        } else {
          this.landingType = 2;
          this.tempWechat = [
            {id: 1, name: "自建站"},
            {id: 2, name: "基木鱼"},
          ];
        }

        this.handlePageChange();
      },
      immediate: true,
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
    handleBaiduUrl() {
      if (!this.baiduUrl) {
        return this.$message.error("请填写联调地址");
      }
      this.baiduLoading = false;
      BaiduLandingPageLink({
        url: this.baiduUrl,
      })
        .then((res) => {
          this.$message.success("成功");
        })
        .finally(() => {
          this.baiduLoading = false;
        });
    },
    handleMore(data, row) {
      switch (data) {
        case "delete":
          this.handleDelete(row);
          break;
        case "callback":
          this.handleDetail(row);
          break;
        case "unCallback":
          this.handleUnCallback(row);
          break;
        case "uploadLink":
          this.handleUploadLink(row);
          break;
        default:
          break;
      }
    },
    handleUnCallback(row) {
      this.showUnCallback = true;
      this.DetailData = row;
    },
    handleUploadLink(row) {
      this.showUploadLink = true;
      this.DetailData = row;
    },
    handleDetail(row) {
      this.showDetail = true;
      this.DetailData = row;
    },
    handleAddTime() {
      this.timeList.push({
        startDate: "",
        endDate: "",
      });
    },
    handleDeleteTime(index) {
      this.timeList.splice(index, 1);
    },
    handleClickDingImg() {
      this.$refs.Dingfile.click();
    },
    handleClickWxImg() {
      this.$refs.file.click();
    },
    WxImgFileChange(e) {
      const fileList = e.srcElement.files;
      this.files = e.srcElement.files;
      const file = fileList[0];
      if (file) {
        if (file.size > 1024 * 1024 * 1.5) {
          this.$message.warning("图片大小不能超过1.5M，请重新选择图片");
          return false;
        }
        uploadFile({file}).then((res) => {
          this.wximgUrl = res;
          this.sendForm.wxHeadUrl = res;
        });
      }
    },
    DingImgFileChange(e) {
      const fileList = e.srcElement.files;
      this.files = e.srcElement.files;
      const file = fileList[0];
      if (file) {
        if (file.size > 1024 * 1024 * 1.5) {
          this.$message.warning("图片大小不能超过1.5M，请重新选择图片");
          return false;
        }
        uploadFile({file}).then((res) => {
          this.imgUrl = res;
          this.sendForm.HeadUrl = res;
        });
      }
    },
    handleDeleteImg() {
      this.imgUrl = "";
    },
    handleDeleteWxImg() {
      this.wximgUrl = "";
    },
    handleCopyLink(item) {
      var input = document.querySelector(".hidden-input");
      if (this.landingType === 1) {
        input.value = `https://nods.gaohaiwx.com/api/upload/landingPage/jump/control?appflag=${item.appid}&landingPageId=${item.id}&oaid=__OAID__&aid=__AID__&cid=__CID__&callback_url=__CALLBACK_URL__&ip=__IP__&ua=__UA__&callback=__CALLBACK_PARAM__`;
      } else {
        input.value = `https://nods.gaohaiwx.com/api/upload/landingPage/jump/baidu/control?appflag=${item.appid}&landingPageId=${item.id}&oaid=__OAID__&aid=__IDEA_ID__&uid=__UNIT_ID__&ip=__IP__&ua=__UA__&bd_vid=__BD_VID__&callback_url=__CALLBACK_URL__`;
      }
      input.select();
      document.execCommand("copy");
      this.$message.success("复制成功");
    },
    handleDelete(item) {
      this.$confirm("确定要删除此条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteGroundList(item.id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    handleSubmit() {
      this.sendForm.content = this.wangEditor.txt
        .html()
        .replace(/<p><\/p>/gim, "");
      if (!this.showWangEditor) {
        this.sendForm.content = "";
      }
      // 参数判断
      if (!this.$tyParams(this.paramsJudge)) {
        return;
      }
      if (this.isEdit) {
        updateGroundList(this.editId, {
          link: this.sendForm.link,
          title: this.sendForm.title,
          companyName: this.sendForm.companyName,
          wechatNumber: this.sendForm.wechatNumber,
          type: this.sendForm.type,
          accountChannelId: this.sendForm.accountChannelId.id,
          linkAddress: this.sendForm.linkAddress,
          headUrl: this.imgUrl,
          wxHeadUrl: this.wximgUrl,
          remark: this.sendForm.remark,
          callbackRate: this.sendForm.callbackRate,
          content: this.sendForm.content,
          channelName: this.sendForm.channelName,
          landingType: this.sendForm.landingType,
          linkType: this.sendForm.linkType,
          backgroundColor: this.sendForm.backgroundColor,
          settingTimeList: this.timeList,
        }).then((res) => {
          this.$message.success("新增成功");
          this.sendForm = {};
          this.showEdit = false;
          this.isEdit = false;
          this.handlePageChange();
        });
      } else {
        addGroundList({
          link: this.sendForm.link,
          title: this.sendForm.title,
          companyName: this.sendForm.companyName,
          wechatNumber: this.sendForm.wechatNumber,
          type: this.sendForm.type,
          accountChannelId: this.sendForm.accountChannelId.id,
          linkAddress: this.sendForm.linkAddress,
          headUrl: this.imgUrl,
          wxHeadUrl: this.wximgUrl,
          remark: this.sendForm.remark,
          callbackRate: this.sendForm.callbackRate,
          content: this.sendForm.content,
          channelName: this.sendForm.channelName,
          landingType: this.sendForm.landingType,
          linkType: this.sendForm.linkType,
          backgroundColor: this.sendForm.backgroundColor,
          settingTimeList: this.timeList,
          platform: this.landingType,
        }).then((res) => {
          this.$message.success("新增成功");
          this.sendForm = {};
          this.showEdit = false;
          this.isEdit = false;
          this.handlePageChange();
        });
      }
    },
    handlePageChange(page) {
      this.page = typeof page === "number" ? page : this.page;
      getGroundList({
        search: this.searchWords,
        accountChannelId: this.accountChannelId,
        page: this.page,
        pageSize: this.pageSize,
        platform: this.landingType,
      }).then((res) => {
        this.total = res.total;
        this.tableData = res.list;
      });
    },
    handleEdit(item) {
      this.editId = item.id;
      item.id ? (this.isEdit = true) : (this.isEdit = false);
      if (this.isEdit) {
        this.isFirstInEdit = true;
        this.imgUrl = item.headUrl;
        this.wximgUrl = item.wxHeadUrl;
        this.sendForm = {
          link: item.link,
          title: item.title,
          remark: item.remark,
          companyName: item.companyName,
          wechatNumber: item.wechatNumber,
          type: item.type,
          linkAddress: item.linkAddress,
          headUrl: item.headUrl,
          callbackRate: item.callbackRate,
          content: item.content,
          accountChannelId: {
            id: item.accountChannelId,
          },
          channelName: item.channelName,
          landingType: item.landingType,
          linkType: item.linkType,
          backgroundColor: item.backgroundColor,
        };
        this.timeList = item.settingTimeList
          ? item.settingTimeList
          : [
            {
              startDate: "09:00:00",
              endDate: "18:00:00",
            },
          ];
        let tmpChannel = null;
        this.channelListAll.forEach((element) => {
          if (element.id === item.accountChannelId) {
            tmpChannel = element;
          }
        });
        if (tmpChannel) {
          this.handleEditChannelChange(tmpChannel);
        }
      } else {
        this.isFirstInEdit = false;
        this.imgUrl = null;
        this.wximgUrl = null;
        this.sendForm = {
          callbackRate: 100,
          type: 1,
          companyName: "重庆吉吉猫网络科技有限公司",
          landingType: "LINK",
          linkType: 1,
        };
      }
      this.showEdit = true;
    },
    handleSearchWords() {
      this.handlePageChange();
    },
    handleCancle() {
      this.wangEditor = null;
      this.bookDetail = null;
      this.isEdit = false;
      this.showEdit = false;
      this.sendForm = {};
    },
    getResourceType(type) {
      this.resourceType = type;
      this.handleIframe();
    },
    handleIframeClose() {
      this.resourceType = null;
      this.showIframe = false;
    },
    handleBookShow() {
      this.showBook = true;
    },
    handleEditChannelChange(item) {
      if (this.isFirstInEdit) {
        this.isFirstInEdit = false;
      } else {
        this.$set(this.sendForm, "channelName", item.channelName);
      }
      let tmpSelectChanel = this.channelListAll.find((element) => {
        return element.platform === item.platform;
      });
      if (tmpSelectChanel) {
        this.selectChannel = {
          id: tmpSelectChanel.id,
          channelName: tmpSelectChanel.platformStr,
          platform: tmpSelectChanel.platform,
        };
        this.handlePlatformChange(this.selectChannel.platform);
        this.bookSrc = "";
        this.isIframeLoadComplete = false;
        this.bookDetail = null;
      }
    },
    bookChooseSuccess(value) {
      this.bookDetail = value;
    },
    handleIframe() {
      // !判断是否安装了浏览器插件
      if (!this.isInstallChromeExtension()) {
        this.$message({
          showClose: true,
          message: "请安装浏览器插件!",
          type: "warning",
        });
        return;
      }
      if (!this.testChannelInfo) {
        this.$message({
          showClose: true,
          message: "该平台分类下暂无相关公众号!",
          type: "warning",
        });
        return;
      }
      getAccountStatus(this.testChannelInfo.id).then((res) => {
        // 登录
        if (res.isLogin) {
          chrome.runtime.sendMessage(
            this.extendId,
            {
              novelapi: "setOperationFlag",
              flag: true,
            },
            ({code}) => {
              if (code === 200) {
                chrome.runtime.sendMessage(
                  this.extendId,
                  {
                    platformResource: "platformResource",
                    platformResourceData: res,
                    platformId: this.selectChannel.platform,
                    operateFlag: true,
                  },
                  ({code, res}) => {
                    if (code === 200) {
                      let {url, host} = this.getBookSrc();
                      this.host = host;
                      this.bookSrc = url;
                      this.$nextTick(() => {
                        this.iframeLoading = this.isIframeLoadComplete
                          ? false
                          : true;

                        chrome.runtime.sendMessage(
                          this.extendId,
                          {
                            novelapi: "closeSameDomainTabs",
                            hostInfo: {
                              host: this.host,
                              platform: this.selectChannel.platform,
                            },
                          },
                          ({code}) => {
                            if (code === 200) {
                              this.showIframe = true;
                              this.checkIframeLoadingStats();
                            }
                          }
                        );
                      });
                    }
                  }
                );
              }
            }
          );
          // 未登录
        } else {
          this.handleLogin();
        }
      });
    },
    // 判断是否安装了对应的浏览器插件
    isInstallChromeExtension() {
      return typeof chrome && !!chrome.runtime;
    },
    handlePlatformChange(newV) {
      getLoginAccount({
        page: 1,
        pageSize: 15,
        platform: newV,
      }).then((res) => {
        if (res.list.length === 0) {
          this.testChannelInfo = null;
        } else {
          // !默认取每个平台的"第一个"公众号的信息
          this.testChannelInfo = res.list[0];
        }
      });
    },
    getBookSrc() {
      let {
        chapter: {bookArticleId, endIdx},
        book: {bookId, bookName},
      } = this.bookDetail;
      let targetValue = "";
      // 阳光 || 七悦
      if (
        this.selectChannel.platform === 3 ||
        this.selectChannel.platform === 6
      ) {
        if (this.resourceType === 1) {
          targetValue = "essay";
        }
        if (this.resourceType === 2) {
          targetValue = "essayimage";
        }
        if (this.resourceType === 3) {
          targetValue = "background";
        }
      }
      // 忧书阁
      if (this.selectChannel.platform === 4) {
        if (this.resourceType === 1) targetValue = "word_show";
        if (this.resourceType === 2) targetValue = "img_oneslef";
        if (this.resourceType === 3) targetValue = "img_oneself_show_bgd";
      }

      let platformList = [
        "zhangzhongyun",
        "yuewen",
        "zhuishuyun",
        "yangguang",
        "youshuge",
        "wangyi",
        "qiyue",
        "huasheng",
        "haidu",
      ];
      let platformSrc = {
        zhangzhongyun: {
          url: `https://inovel.818tu.com/backend/wx_article_editor?aid=${
            Number(bookArticleId) - 1
          }&mode=text`,
          host: "https://inovel.818tu.com",
        },
        yuewen: {
          url: `https://open.yuewen.com/new/imgPromotion?cbid=${bookId}&ccbid=${
            Number(bookArticleId) - 1
          }&id=${endIdx}&book=${bookName}`,
          host: "https://open.yuewen.com",
        },
        zhuishuyun: {
          url: "暂无",
          host: "",
        },
        yangguang: {
          url: `https://admin.kydba.cn/admin/referral.referral/${targetValue}/book_id/${bookId}/chapter_idx/${endIdx}`,
          host: "https://admin.kydba.cn",
        },
        youshuge: {
          url: `https://novel.qinronread.com/article/${targetValue}/${bookId}/${
            this.resourceType !== 1 ? Number(bookArticleId) - 1 : ""
          }`,
          host: "https://novel.qinronread.com",
        },
        wangyi: {
          url: "暂无",
          host: "",
        },
        qiyue: {
          url: `https://zhangwenwenhua.com/admin/referral.referral/${targetValue}/book_id/${bookId}/chapter_idx/${endIdx}`,
          host: "https://zhangwenwenhua.com",
        },
        huasheng: {
          url: "https://ad.rlcps.cn/#/works/fiction",
          host: "https://ad.rlcps.cn",
        },
        haidu: {
          url: "http://managers.haidubooks.com/defaultSummary/summaryInfo",
          host: "https://ad.rlcps.cn",
        },
      };
      return platformSrc[`${platformList[this.selectChannel.platform]}`];
    },
    checkIframeLoadingStats() {
      this.$nextTick(() => {
        let iframe = document.querySelector(".iframe-resource");
        iframe.onload = () => {
          this.iframeLoading = false;
          this.isIframeLoadComplete = true;
        };
      });
    },
    // 刷新iframe
    refreshIframePage() {
      let iframe = document.querySelector(".iframe-resource");
      if (iframe) {
        window.open(iframe.src, "ifrmname", "");
      }
    },
    handleUploadImg(value) {
      this.imgUrl = value;
      this.sendForm.headUrl = value;
    },
    handleUploadWxImg(value) {
      this.wximgUrl = value;
      this.sendForm.wxHeadUrl = value;
    },
    handleLogin() {
      if (!this.isInstallChromeExtension()) {
        this.$message({
          showClose: true,
          message: "请安装浏览器插件!",
          type: "warning",
        });
        return;
      }
      chrome.runtime.sendMessage(this.extendId, {
        novelapi: "resetOperationFlag",
      });
      // * 阅文
      // console.log(this.sendForm.accountChannelId, "sendForm.accountChannelId");
      // return;
      if (this.sendForm.accountChannelId.platform === 1) {
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: "clearCookie",
            url: "https://open.yuewen.com",
          },
          (resString) => {
            let {code} = JSON.parse(resString);
            if (code === 200) {
              this.showAuthCode = false;
              window.open(
                `https://open.yuewen.com/new/login?id=${this.testChannelInfo.id}`
              );
            }
          }
        );
      }
      // * 花生书城
      if (this.sendForm.accountChannelId.platform === 7) {
        //花生书城
        localStorage.removeItem("cpsad");
        window.open(`https://ad.rlcps.cn/#/?id=${this.testChannelInfo.id}`);
      }
      //* 追书云、悠书阁、花生书城
      else if ([2, 4].includes(this.sendForm.accountChannelId.platform)) {
        this.showLoadingMask = true;
        // 通过web端发送获取验证码的信息给浏览器插件的后台脚本(background.js)
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: "getValidCode",
            id: this.testChannelInfo.id,
          },
          (resString) => {
            let {code, res} = JSON.parse(resString);
            this.loadingAuthCode = false;
            if (code === 200) {
              this.showLoadingMask = false;
              this.showCodeImg = true;
              this.showAuthCode = true;
              if (this.sendForm.accountChannelId.platform === 7) {
                this.codeURL = res.data.img;
                this.codeCookie = res.data.key;
              } else {
                this.codeURL = res.base64Code;
                this.codeCookie = res.cookies;
              }
            } else {
              this.$message({
                showClose: true,
                message: "获取验证码出错!",
                type: "error",
              });
              this.showLoadingMask = false;
              return;
            }
          }
        );
      } else {
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: "getCookieById",
            data: {
              id: this.testChannelInfo.id,
              host: hostArr[this.sendForm.accountChannelId.platform].host,
              type: 0,
            },
          },
          (resString) => {
            this.loadingAuthCode = false;
            // * 海读书城
            if (this.sendForm.accountChannelId.platform === 8) {
              chrome.runtime.sendMessage(
                this.extendId,
                {
                  novelapi: "setHaiduLocalStorage",
                  data: {
                    SET_ROLES: [`${resString.data.data.userType}`],
                    token: resString.data.data.token,
                    userName: resString.data.data.list[0].userName,
                  },
                },
                () => {
                  this.showAuthCode = false;
                  if (resString.code === 200) {
                    window.open(
                      hostArr[this.sendForm.accountChannelId.platform].url
                    );
                  } else {
                    this.$message({
                      showClose: true,
                      message: "获取海读相关数据失败，请稍后再试!",
                      type: "error",
                    });
                  }
                }
              );
            } else {
              let {code, res} = JSON.parse(resString);
              this.showAuthCode = false;
              if (code === 200) {
                window.open(
                  hostArr[this.sendForm.accountChannelId.platform].url
                );
              } else {
                this.$message({
                  showClose: true,
                  message: "获取相关平台数据失败，请稍后再试!",
                  type: "error",
                });
              }
            }
          }
        );
      }
    },
  },
  created() {
    // 切换路由更新landingtype已获取百度、头条信息
    if (this.$route.name == "LandingPageTT") {
      this.landingType = 1;
    } else {
      this.landingType = 2;
      this.tempWechat = [
        {id: 1, name: "自建站"},
        {id: 2, name: "基木鱼"},
      ];
    }
    // this.handlePageChange();
  },
  filters: {
    typeFilter: function (value) {
      if (this.landingType == 1) {
        switch (value) {
          case 1:
            return "自建站";
          case 2:
            return "橙子建站";
          default:
            return "自建站";
        }
        //百度
      } else {
        switch (value) {
          case 1:
            return "自建站";
          case 2:
            return "积木鱼";
          default:
            return "自建站";
        }
      }
    },
  },
  components: {
    backUpDown,
    UploadImage,
    chooseBook,
    landingDetail,
    landingUpload,
    unCallback,
  },
  computed: {
    paramsJudge() {
      let timeFlag = this.timeList.some((item) => {
        if (!item.startDate || !item.startDate) {
          return true;
        } else {
          return false;
        }
      });
      return [
        {
          flag: !this.sendForm.accountChannelId,
          text: "请选择公众号",
        },
        {
          flag: !this.sendForm.channelName,
          text: "请输入公众号名称",
        },
        {
          flag: !this.sendForm.link,
          text: "请填写网站链接",
        },
        {
          flag: !this.sendForm.companyName,
          text: "请填写公司名",
        },
        {
          flag: !this.sendForm.wechatNumber,
          text: "请填写微信号",
        },
        {
          flag: !this.sendForm.type,
          text: "请选择平台",
        },
        {
          flag: !this.sendForm.linkAddress,
          text: "请输入关注链接",
        },
        {
          flag: !this.imgUrl && this.showWangEditor,
          text: "请上传头部图片",
        },
        {
          flag: !this.sendForm.callbackRate,
          text: "请填写回传率",
        },
        {
          flag: timeFlag,
          text: "请完善回传时间",
        },
        {
          flag: !this.sendForm.content && this.showWangEditor,
          text: "请填写文案",
        },
      ];
    },
    availableOfficialList() {
      let tmpList = [];
      this.officialList.forEach((item) => {
        if (item.status === 1) {
          tmpList.push(item);
        }
      });
      return tmpList;
    },
    channelList() {
      const {officialList} = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    channelListAll() {
      const {officialList} = this;
      return officialList.filter((item) =>
        messagePlatformList.includes(item.platform)
      );
    },
    ...mapGetters(["officialList"]),
  },
};
</script>

<style lang="scss" scoped>
.ground-content {
  height: 100%;

  .operation {
    background: #fff;
    box-sizing: border-box;
    // padding:  0 20px;
  }

  .ground-table {
    background: #fff;
  }
}

.form-container {
  width: 1620px;
  margin: auto;
}

.form-top {
  padding: 0 20px;
}

.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;

  .message-box {
    justify-content: flex-start;
  }

  .form-body-left {
    padding: 0 10px;
    border-right: 1px dashed #d9d9d9;

    .select-book {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        width: 360px;
      }
    }

    .time-list {
      .time-item ~ .time-item {
        margin-top: 4px;
      }

      .add-time {
        padding: 4px !important;
      }
    }
  }

  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 800px;
  }

  .form-body-right {
    padding-left: 10px;
    position: relative;

    .insert-button {
      position: absolute;
      top: 52px;
      right: 10px;
      z-index: 10002;
    }
  }
}

/deep/ .el-dialog__body {
  padding-top: 0 !important;
}

/deep/ .necessary > .el-form-item__label {
  position: relative;
}

/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.main-table {
  img {
    width: 60px;
    height: 60px;
  }
}

iframe {
  width: 100%;
  height: 800px;
  margin: 0 auto;
}

.iframe-dialog {
  // position: relative;
  /deep/ .el-dialog__header {
    padding-bottom: 30px !important;
    text-align: center !important;

    .el-dialog__title {
      font-weight: bold;
      font-size: 20px !important;
    }
  }

  /deep/ .el-dialog__body {
    padding-top: 0 !important;
  }

  .loading-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #969696;
    font-size: 16px;
  }
}

.headImg {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}

.img-over {
  opacity: 0;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: relative;
  top: -110px;
  color: #f56c6c;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

// 隐藏copyinput
.hidden-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
</style>
